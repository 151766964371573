<template>
  <div>
    <div class="wrapper">
      <div class="weatherDiv">
        <div class="weathers">
          <ch-icon :name="weather" />
          <span class="fontWeather">{{ weather }}</span>
          <span class="weatherTp">{{ temp }}°</span>
        </div>
        <div class="weatherbutt">
          <p class="weatdown">{{ city }}</p>
          <p class="weatdown">{{ nowData }}</p>
          <p class="weatdown">{{ AQIStr }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs";
export default {
  data() {
    return {
      temp: "", //度数
      city: "广东-广州", //城市名
      weather: "无", // 天气
      AQIStr: "无", // 污染系数
      nowData: "**/**", //当前日期
      weatherImg: "",
    };
  },
  created() {
    this.nowData = dayjs(new Date()).format("MM/DD")
    // jsonp 请求
    axios.jsonp = (url,data) => {
      if(!url)
        throw new Error('url is necessary')
      const callback = 'CALLBACK' + Math.random().toString().substr(9,18)
      const JSONP = document.createElement('script')
      JSONP.setAttribute('type','text/javascript')
      const headEle = document.getElementsByTagName('head')[0]
      let ret = '';
      if(data){
        if(typeof data === 'string')
          ret = '&' + data;
        else if(typeof data === 'object') {
          for(let key in data)
            ret += '&' + key + '=' + encodeURIComponent(data[key]);
        }
        ret += '&_time=' + Date.now();
      }
      JSONP.src = `${url}?callback=${callback}${ret}`;
      return new Promise( (resolve) => {
        window[callback] = r => {
          resolve(r)
          headEle.removeChild(JSONP)
          delete window[callback]
        }
        headEle.appendChild(JSONP)
      })

    }
    axios.jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
      key:'4HRBZ-2LZ6O-F23WX-SDB2S-T32NZ-GWBIR',
      output: 'jsonp'
    }).then(res => {
      let {province, city, district} = res.result.ad_info
      this.city = city
      axios.jsonp('https://wis.qq.com/weather/common', {
        source: 'pc',
        weather_type: 'observe|forecast_1h|forecast_24h|index|alarm|limit|tips|air|rise',
        province: province,
        city: city,
        county: district
      }).then(res => {
        let { weather, degree } = res.data.observe
        this.weather = weather
        this.temp = degree
        // 空气质量
        this.AQIStr = res.data.air.aqi_name
      }).catch(() => {
        console.log('获取天气失败')
      })
    }).catch((err) => {
      console.log('获取IP地址失败', err)
    })

  },
};
</script>

<style scoped>
/* 图片*/
.CH_icon {
  font-size: 24px;
}

.fontWeather {
  font-size: 12px;
  padding: 4px 6px 0 0px;
}

.wrapper {
  padding: 10px 30px 10px 30px;
}

/* 天气 */
.weatherDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 2%;
  width: auto;
  height: 80%;
}

.weatherbutt {
  padding: 2px;
  width: 100%;
  font-size: 10px;
  display: flex;
  justify-content: center;
}
.weatdown {
  margin: 0 3px 0 3px;
}


</style>
